import DefaultModal from "./DefaultModal";
import loading_spinner from '../../Assets/spinner-loading.svg';
import '../../UI/Common/Loading.css'


interface Props {
    show: boolean;
}

const Loading: React.FC<Props> = ({ show }) => {

    return (
        <DefaultModal show={show}>
            <div style={{ marginTop: '2rem', marginBottom: '5rem' }}>
                <div className="default-loading-div">
                    <img style={{ height: '7rem', width: '7rem' }} src={loading_spinner} />
                </div>
                <p className="loading-spinner-p">Carregando...</p>
            </div>
        </DefaultModal>
    )
}


export default Loading;