import '../../../UI/Pages/Checkin/ItemList.css';
import SquareIcon from '../../Common/SquareIcon';
import useRooms from '../../State/Hooks/useRooms';

interface Props {
    title: string;
    text?: string;
    leftIcon?: string;
    rightIcon?: string;
    setPage: any;
    room?: string;
}

//Page 1
const ItemList: React.FC<Props> = ({ title, text, leftIcon, rightIcon, setPage, room }) => {
    const { setCurrentRoom } = useRooms();

    return (
        <div className="item-container" onClick={() => { setPage(2); setCurrentRoom(room) }}>
            {rightIcon && <SquareIcon src={rightIcon} />}

            <div className='center-div' translate="no">

                <h2 className='h2-title'>{title}</h2>
                {text && <p className='p-text'>{text}</p>}

            </div>

            {leftIcon && <img src={leftIcon} />}
        </div>
    )
}

export default ItemList;

