import React from 'react';
import '../../../UI/Common/Inputs/RadioInput.css';

interface Props {
    checked: boolean;
    label: string;
    value: any;
    onChange?: any;
}

const RadioInput: React.FC<Props> = ({ checked, value, onChange, label }) => {

    return (
        <label className={`radio-input-label ${checked ? 'checked' : ''}`}>
            <input
                className='radio-input-option'
                type="radio"
                value={value}
                checked={checked}
                onChange={onChange}
            />
            {label}
        </label>
    );
}

export default RadioInput;