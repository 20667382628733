import '../../../../UI/Pages/App/Notifications/Notifications.css'
import NotificationItem from "../../App/Notifications/NotificationItem";
import AppBackground from "../AppBackground";
import AppHeader from "../AppHeader";
import icon_spacer from '../../../../Assets/Icons/icon-spacer-24-px.svg';
import arrow_left from '../../../../Assets/Icons/icon-arrow-left-white.svg';
import DefaultTitle from "../../../Common/DefaultTitle";
import { useNavigate } from "react-router-dom";
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import PageTransition from '../../../Common/PageTransition';
import DefaultButton from '../../../Common/Buttons/DefaultButton';


const Notifications = () => {
    const navigate = useNavigate();
    const { messages } = useCurrentUser();


    return (
        <AppBackground>
            <PageTransition>
                <div className='notifications-main-div'>
                    <AppHeader
                        leftButton={
                            <button className='app-header-icon-button' onClick={() => navigate('/')}>
                                <img src={arrow_left} alt="Logout Icon" style={{ marginLeft: '-1.5rem' }} />
                            </button>}

                        rightButton={<img src={icon_spacer} />}
                    />

                    <div style={{ marginTop: '4.2rem' }}>
                        <DefaultTitle text="Notificações" />

                        <div className="notification-list">
                            {messages.map(m => <NotificationItem key={m.id} data={m} />)}
                        </div>
                    </div>
                </div>
            </PageTransition>
        </AppBackground >
    )
}

export default Notifications;