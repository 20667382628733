import '../../../../UI/Pages/App/Interactions/InteractionHeader.css';

interface Props {
    leftButton?: React.ReactNode;
    rightButton?: React.ReactNode;
    title?: string;
}

const InteractionHeader: React.FC<Props> = ({ leftButton, rightButton, title }) => {
    return (
        <div className='interaction-header'>
            {leftButton}
            <p className='header-title-p'>{title}</p>
            {rightButton}
        </div>
    );
}

export default InteractionHeader;