import '../../UI/Common/DefaultTitle.css';

interface Props {
    text: string;
}

const DefaultTitle: React.FC<Props> = ({ text }) => {

    return (
        <p className='default-title'>{text}</p>
    )
}

export default DefaultTitle;

