import '../../UI/Common/DefaultLabel.css';

interface Props {
    text: string
    htmlFor: string;
}

const DefaultLabel: React.FC<Props> = ({ text, htmlFor }) => {

    return (
        <label className="default-label" htmlFor={htmlFor}>{text}</label>
    )
}

export default DefaultLabel;

