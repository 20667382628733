import React from 'react';
import '../../../UI/Common/Buttons/DefaultButton.css';
import icon_spacer from '../../../Assets/Icons/icon-spacer-24-px.svg';

interface Props {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type: 'button' | 'submit' | 'reset';
    iconLeft?: string;
    iconRight?: string;
    text?: string;
    disabled?: boolean;
    backgroundColor?: string;
    textColor?: string;
    border?: string;
}

const DefaultButton: React.FC<Props> = ({ type, text, disabled, iconRight, iconLeft, onClick, backgroundColor, textColor, border }) => {
    return (
        <button
            className={"default-button"}
            type={type}
            onClick={onClick}
            disabled={disabled}
            style={{ backgroundColor: backgroundColor, color: textColor, border: border }}
        >
            <img src={iconLeft ?? icon_spacer} alt="" />
            {text && <p className="button-text">{text}</p>}
            <img src={iconRight ?? icon_spacer} alt="" />
        </button>
    );
}

export default DefaultButton;
