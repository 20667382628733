import '../../UI/Common/EmojiBox.css';
import React from 'react';

interface Props {
    selected: boolean;
    emoji?: any;
    onEmojiClick: any;
}

const EmojiBox: React.FC<Props> = ({ emoji, selected, onEmojiClick }) => {
    return (
        <div className={'emoji-box-container'} onClick={() => onEmojiClick(emoji.id)}>
            <div className={selected ? 'emoji-div-selected' : 'emoji-div'}>
                <img className='emoji-box-image' src={emoji.src} alt={emoji.title} />
            </div>
            <p className={selected ? 'emoji-title-selected-p' : 'emoji-title-p'}>{emoji.title}</p>
        </div>
    )
}

export default EmojiBox;