import React from "react";
import ppl_asset from '../../../../Assets/raphael-people.svg';
import '../../../../UI/Pages/App/Room/EmptyRoom.css'


const EmptyRoom: React.FC = () => {
    return (
        <div className="empty-room-div">
            <img src={ppl_asset} />
            <p className="empty-room-title-p">Aguardando outros usuários</p>
            <p className="empty-room-text-p">Talvez você seja o primeiro por aqui, que tal disseminar a palavra do Blinker para os amigos?</p>
        </div>
    )
};

export default EmptyRoom;