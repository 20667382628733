import React from 'react';
import close_icon from '../../Assets/Icons/icon-close-white.svg'
import '../../UI/Common/DefaultModal.css';

interface Props {
    show: boolean;
    onClose?: any;
    children: React.ReactNode;
    showCloseButton?: boolean
}

const DefaultModal: React.FC<Props> = ({ show, onClose, showCloseButton, children }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="overlay" onClick={onClose}></div>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                {showCloseButton && (
                    <button className="modal-close-button" onClick={onClose}>
                        <img src={close_icon} alt="Fechar" />
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default DefaultModal;
