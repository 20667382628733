import '../../../UI/Pages/Checkin/UserForm.css';
import right_icon from '../../../Assets/Icons/icon-arrow-right.svg';
import spacer_icon from '../../../Assets/Icons/icon-spacer-24-px.svg';
import CheckinBackground from "./CheckinBackground";
import DefaultLabel from "../../Common/DefaultLabel";
import FormInput from "../../Common/Inputs/FormInput";
import { useEffect, useState } from "react";
import ReturnButton from "../../Common/Buttons/ReturnButton";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultSubtitle from '../../Common/DefaultSubtitle';
import useCurrentUser from '../../State/Hooks/useCurrentUser';
import PageTransition from '../../Common/PageTransition';
import RadioInput from '../../Common/Inputs/RadioInput';
import useRooms from '../../State/Hooks/useRooms';

interface Props {
    setPage: any;
}

const genders = [
    { value: "Homem", label: "Homem" },
    { value: "Mulher", label: "Mulher" },
    { value: "Outro", label: "Outro / Não especificar" },
]

//Page 2
const UserForm: React.FC<Props> = ({ setPage }) => {
    const { currentUser, setCurrentUser } = useCurrentUser();
    const { currentRoom } = useRooms();
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [selectedGender, setSelectedGender] = useState('');

    window.scrollTo(0, 0);

    useEffect(() => {
        if (currentUser) {
            setName(currentUser.name || '');
            setPhone(currentUser.phone || '');
            setSelectedGender(currentUser.gender || '');
        }
    }, [currentUser]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value.length <= 11) {
            if (value.length > 2) {
                value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); // Adiciona parênteses em torno dos dois primeiros dígitos
            }
            if (value.length > 7) {
                value = value.replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona um traço antes dos últimos quatro dígitos
            }
            setPhone(value);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setCurrentUser({ name, phone, gender: selectedGender, room: currentRoom.id });
        setPage(3);
    };

    return (
        <CheckinBackground>
            <PageTransition>
                <div className='user-form-container'>
                    <form className="form-container" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <DefaultLabel text={"Seu nome"} htmlFor={"name"} />
                            <FormInput
                                type={"text"}
                                id={"name"}
                                value={name}
                                placeholder={"Digite..."}
                                maxLength={22}
                                onChange={handleNameChange}
                            />
                            {/* <DefaultSubtitle text='*Visível a outros usuários' /> */}
                        </div>

                        <div className="form-group">
                            <DefaultLabel text={"Telefone"} htmlFor={"phone"} />
                            <FormInput
                                type={"text"}
                                id={"phone"}
                                value={phone}
                                placeholder={"(00) 00000-0000"}
                                onChange={handlePhoneChange} />
                            <DefaultSubtitle text='*Este dado será oculto' />
                        </div>

                        <div className='user-form-gender'>
                            <DefaultLabel text={"Gênero com o qual se identifica"} htmlFor={"gender"} />
                            {genders.map(gender =>
                                <RadioInput
                                    key={gender.value}
                                    checked={selectedGender === gender.value}
                                    label={gender.label}
                                    value={gender.value}
                                    onChange={(event: any) => setSelectedGender(event.target.value)}
                                />
                            )}
                            {/* <DefaultSubtitle text='*Visível a outros usuários' /> */}
                        </div>

                        <div className="form-actions">
                            <ReturnButton type="button" onClick={() => setPage(1)} />
                            <DefaultButton
                                type="submit"
                                text={"Próximo"}
                                iconRight={right_icon}
                                iconLeft={spacer_icon}
                                disabled={!name || phone.length < 15 || !selectedGender}
                            />
                        </div>
                    </form>
                </div>
            </PageTransition>
        </CheckinBackground>
    );
}

export default UserForm;