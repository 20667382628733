import '../../../../UI/Pages/App/Notifications/NotificationItem.css';
import love_letter from '../../../../Assets/Icons/love-letter-1.svg'
import { formatTimestamp } from '../../../../lib/firebase/firestore';
import useCurrentUser from '../../../State/Hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import useEmojis from '../../../State/Hooks/useEmojis';

type Message = {
    id: string;
    userFrom: string;
    userTo: string;
    message: any;
    createdAt: any;
    closed: boolean;
}

interface Props {
    data: Message;
    text?: string;
}

const NotificationItem: React.FC<Props> = ({ data, text }) => {
    const { userFrom, message, createdAt, closed, id: notification_id } = data;
    const { usersList } = useCurrentUser();
    const { emojis } = useEmojis();
    const candidate = usersList?.find(u => u.id == userFrom);
    const receivedEmoji = emojis?.find(e => e.id == message);

    const navigate = useNavigate();

    return (
        <div className={closed ? "item-container-closed" : "item-container-opened"} onClick={() => navigate(`/notification/${notification_id}`)}>
            <div className={closed ? 'icon-div-closed' : 'icon-div-opened'}>
                <img className={closed ? 'image-size-closed' : 'image-size-opened'} src={closed ? love_letter : receivedEmoji?.src} />
            </div>


            <div className={'center-div'} translate="no">

                <h2 className={closed ? 'h2-title-opened' : 'h2-title-closed'}>{
                    closed
                        ? "Nova mensagem"
                        : (<><strong>{candidate?.name}</strong> {receivedEmoji?.text}</>)}
                </h2>

                {closed && <p className='p-text'>Clique para abrir</p>}

            </div>


            <div className='div-time'>
                <p className={closed ? 'p-time-closed' : 'p-time-opened'}>{formatTimestamp(createdAt)}</p>
            </div>
        </div>
    )
}

export default NotificationItem;

