import DefaultModal from "./DefaultModal";
import loading_spinner from '../../Assets/Icons/error-outline.svg';
import '../../UI/Common/ErrorMessage.css'


interface Props {
    show: boolean;
    onClose: any;
    message?: string;
}

const ErrorMessage: React.FC<Props> = ({ show, message, onClose }) => {

    return (
        <DefaultModal show={show} onClose={onClose}>
            <div style={{ marginTop: '2rem', marginBottom: '5rem' }}>
                <div className="default-error-div">
                    <img style={{ height: '2.75rem', width: '2.75rem' }} src={loading_spinner} />
                    <p className="default-error-p">Ops, ocorreu um erro</p>
                    <p className="optinal-error-message">{message ?? 'Tente novamente em alguns minutos'}</p>
                    <div style={{ display: "flex", width: '55%', marginTop: '1rem', marginBottom: '-1rem' }}>
                        <button className="default-error-button" onClick={onClose}>
                            <p className="error-button-p">OK</p>
                        </button>
                    </div>
                </div>
            </div>
        </DefaultModal>
    )
}


export default ErrorMessage;