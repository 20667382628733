import React from 'react';
import '../../../../UI/Pages/App/PerfilCard.css';

type User = {
    id: string;
    name: string;
    photo: string;
}
interface Props {
    user: User;
    onBlink: any;
}

const PerfilCard: React.FC<Props> = ({ user, onBlink }) => {
    return (
        <div className="photo-container" onClick={onBlink}>
            <img src={user.photo} alt={user.name} className="photo" />
            <div className="caption">{user.name}</div>
        </div>
    );
};

export default PerfilCard;