import React, { useState } from "react";
import '../../../../UI/Pages/App/SendInteraction.css'
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import send_icon from '../../../../Assets/Icons/icon-send.svg';
import EmojiBox from "../../../Common/EmojiBox";
import useEmojis from "../../../State/Hooks/useEmojis";
import { sendMessage } from "../../../../lib/firebase/firestore";
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import { useNavigate, useParams } from "react-router-dom";
import icon_spacer from '../../../../Assets/Icons/icon-spacer-24-px.svg';
import icon_close from '../../../../Assets/Icons/icon-close-white.svg';
import AppBackground from "../AppBackground";
import InteractionHeader from "./InteractionHeader";
import DefaultModal from "../../../Common/DefaultModal";
import SentInteraction from "./SentInteraction";
import PageTransition from "../../../Common/PageTransition";


const SendInteraction: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { emojis } = useEmojis();
    const { currentUser, usersList } = useCurrentUser();
    const [isSentInteraction, setIsSentInteraction] = useState<boolean>(false);

    const candidate = usersList.find(u => u.id == id)

    // const [selectedEmoji, setSelectedEmoji] = useState<string>(emojis?.find(e => e.title == 'Piscadinha')?.id ?? '');
    const [selectedEmoji, setSelectedEmoji] = useState<string>('');

    const navigate = useNavigate();

    const sendInteraction = async (message: any) => {
        currentUser.id && await sendMessage(currentUser.id, candidate?.id, message);
        setIsSentInteraction(true);
    }


    window.scrollTo(0, 0);

    return (
        <AppBackground>
            <PageTransition>
                <div className="send-interaction-main-div">
                    <InteractionHeader
                        leftButton={
                            <button className='interaction-header-icon-button' onClick={() => navigate('/')}>
                                <img src={icon_close} alt="Logout Icon" style={{ paddingLeft: '2rem' }} />
                            </button>}
                        rightButton={<img src={icon_spacer} />}
                        title={candidate?.name}
                    />
                    <div style={{ display: "flex", flexDirection: 'column', gap: '2rem', marginBottom: '4.5rem', alignItems: 'center' }}>
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '-1rem' }}>
                            <img src={candidate?.photo} alt={candidate?.name} className="interaction-photo" />
                        </div>
                        <p className="send-interaction-text-p">Enviar figurinha</p>
                        <div className="send-interaction-grid">
                            {emojis?.filter(e => e.regular === true).map((e) =>
                                <EmojiBox key={e.id} emoji={e} selected={selectedEmoji === e.id} onEmojiClick={setSelectedEmoji} />
                            )}
                        </div>
                    </div >
                    <div className="send-interaction-button-div">
                        <div style={{ paddingInline: '1rem' }}>
                            <DefaultButton type={"button"} text="Enviar" iconRight={send_icon} onClick={() => sendInteraction(selectedEmoji)} disabled={!Boolean(selectedEmoji)} />
                        </div>
                    </div>
                </div>
            </PageTransition>
            <DefaultModal show={isSentInteraction} onClose={() => setIsSentInteraction(false)}>
                <SentInteraction name={candidate?.name} />
            </DefaultModal>
        </AppBackground>
    )
};

export default SendInteraction;