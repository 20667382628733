import { atom } from "recoil";
import { User } from "../Interfaces";
import {
  loadCurrentUser,
  loadEmojis,
  loadRooms,
} from "./Selectors";

export const emojisState = atom({
  key: 'emojisState',
  default: loadEmojis
});

export const currentUserState = atom<User>({
  key: 'currentUserState',
  default: loadCurrentUser
});

export const roomState = atom({
  key: 'roomState',
  default: loadRooms
});
