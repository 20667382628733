import { useRecoilValue, useSetRecoilState } from "recoil";
import { emojisState } from "../atom";

const useEmojis = () => {
    const emojis = useRecoilValue(emojisState);
    const setEmojis = useSetRecoilState(emojisState);
    return { emojis };
}



export default useEmojis;