import logo_extenso from '../../../../Assets/logos/logo_extenso.svg';
import '../../../../UI/Pages/App/Room/RoomHeader.css';

interface Props {
    leftButton?: React.ReactNode;
    rightButton?: React.ReactNode;
}

const RoomHeader: React.FC<Props> = ({ leftButton, rightButton }) => {
    return (
        <div className={'room-header'}>
            <img className='room-logo-header' src={logo_extenso} alt="Logo" />
            <div className='room-header-buttons'>
                {leftButton}
                {rightButton}
            </div>
        </div>
    );
}

export default RoomHeader;