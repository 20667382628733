import React from "react";
import '../../../../UI/Pages/App/SentInteraction.css'
import love_letter from '../../../../Assets/Icons/love-letter-1.svg';
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import { useNavigate } from "react-router-dom";


interface Props {
    name: string;
}

const SentInteraction: React.FC<Props> = ({ name }) => {
    const navigate = useNavigate();

    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1rem', width: '95%',
            marginBottom: 15,
            marginTop: 18
        }}>
            <img src={love_letter} />
            <p className="title-interaction">Mensagem enviada</p >

            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p className="p-interaction">{`${name} recebeu sua mensagem.`}</p >
                <p className="p-interaction">{`Boa sorte <3`}</p >
            </div>

            <div style={{ width: '60%', margin: '1rem' }}>
                <DefaultButton type={"button"} text="OK" onClick={() => navigate('/')} />
            </div>
        </div >
    )
};

export default SentInteraction;