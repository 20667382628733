import React, { useEffect, useState } from "react";
import '../../../../UI/Pages/App/ViewInteraction.css'
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import send_icon from '../../../../Assets/Icons/icon-send.svg';
import icon_close from '../../../../Assets/Icons/icon-close-white.svg'
import icon_spacer from '../../../../Assets/Icons/icon-spacer-24-px.svg'
import EmojiBox from "../../../Common/EmojiBox";
import AppBackground from "../AppBackground";
import useEmojis from "../../../State/Hooks/useEmojis";
import { useNavigate, useParams } from 'react-router-dom';
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import InteractionHeader from "./InteractionHeader";
import DefaultModal from "../../../Common/DefaultModal";
import SentInteraction from "./SentInteraction";
import { sendMessage, updateMessageClosedStatus } from "../../../../lib/firebase/firestore";
import PageTransition from "../../../Common/PageTransition";

const ViewInteraction: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { currentUser, messages, usersList } = useCurrentUser();
    const { emojis } = useEmojis();
    const [isSentInteraction, setIsSentInteraction] = useState<boolean>(false);

    const notification = messages.find(n => n.id == id);
    const candidate = usersList?.find(u => u.id == notification?.userFrom);
    const receivedEmoji = emojis?.find(e => e.id == notification?.message);

    const [selectedEmoji, setSelectedEmoji] = useState<string>('');

    const navigate = useNavigate();


    window.scrollTo(0, 0);

    const textData = <p><span style={{ color: '#FFF' }}>{candidate?.name}</span> {receivedEmoji?.text}</p>;

    const sendInteraction = async (message: any) => {
        currentUser.id && await sendMessage(currentUser.id, candidate?.id, message);
        setIsSentInteraction(true);
    }

    useEffect(() => {
        if (notification?.closed) {
            updateMessageClosedStatus(notification.id, false);
        }
    }, [notification])

    return (
        <AppBackground>
            <PageTransition>
                <div className="view-interaction-main-div">
                    <InteractionHeader
                        leftButton={
                            <button className='interaction-header-icon-button' onClick={() => navigate('/notifications')}>
                                <img src={icon_close} alt="Logout Icon" style={{ paddingLeft: '2rem' }} />
                            </button>}
                        rightButton={<img src={icon_spacer} />}
                        title={candidate?.name}
                    />
                    <div style={{ display: "flex", flexDirection: 'column', gap: '1.5rem', marginBottom: 20, marginTop: '-1rem' }}>
                        <div className="interaction-photo-div">
                            <img src={candidate?.photo} alt={candidate?.name} className="interaction-photo" />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="emoji-div-absolute">
                                <img src={receivedEmoji?.src} className="photo-emoji" />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            <p className="received-emoji-text">{textData}</p>
                        </div>

                        {/* <div className="view-interaction-bar" /> */}

                        <div className="retribution-div">
                            <p className="retribution-p">Enviar resposta</p>
                            <div className="view-interaction-grid">
                                {emojis?.map((e) =>
                                    <EmojiBox key={e.id} emoji={e} selected={selectedEmoji === e.id} onEmojiClick={setSelectedEmoji} />
                                )}
                            </div>
                        </div>
                        <div className="view-interaction-buttons-div">
                            <div style={{ paddingInline: '1rem' }}>
                                <DefaultButton type={"button"} text="Enviar" iconRight={send_icon} onClick={() => sendInteraction(selectedEmoji)} disabled={!Boolean(selectedEmoji)} />
                            </div>
                        </div>
                    </div>
                </div>
            </PageTransition>
            <DefaultModal show={isSentInteraction} onClose={() => setIsSentInteraction(false)}>
                <SentInteraction name={candidate?.name} />
            </DefaultModal>
        </AppBackground >
    )
};

export default ViewInteraction;