import { getToken, onMessage } from 'firebase/messaging';
import { messaging, vapidKey } from './client';

const updateLocalStorage = (currentToken: string) => {
    // console.log('current token for client: ', currentToken);
    if(localStorage.getItem('fcmToken') && currentToken !==localStorage.getItem('fcmToken')){
        localStorage.setItem('fcmToken', currentToken);
    }
    else if(!localStorage.getItem('fcmToken')){
        localStorage.setItem('fcmToken', currentToken);
    }
}

const handleToken = async () => {
    const currentToken = await getToken(messaging, { vapidKey }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
    if (currentToken) {
        updateLocalStorage(currentToken);
        return currentToken;
    }
}

export const requestForToken = async () => {
    const currentToken = await handleToken();

    if (currentToken) {
        return currentToken;
    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                return handleToken();
            }
        });
    }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        // console.log("MESSAGE", payload)
        resolve(payload);
    });
  });

