import { motion } from 'framer-motion';
import React from 'react';


interface Props {
  children: any;
  backwards?: boolean;
}
const PageTransition: React.FC<Props> = ({ children, backwards }) => {

  return (
    <motion.div
      initial={{ x: backwards ? '-100%' : '100%' }}
      animate={{ x: 0 }}
      exit={{ x: backwards ? '100%' : '-100%' }}
      transition={{ duration: 0.23 }}
    >
      {children}
    </motion.div>
  );
};



export default PageTransition;