import { Suspense, useEffect, useState } from "react";
import Checkin from "./Checkin";
import UserForm from "./UserForm";
import TakeSelfie from "./TakeSelfie";
import Instructions from "./Instructions";
import Loading from "../../Common/Loading";


const LoginFlow = () => {
    const [currentLoginPage, setCurrentLoginPage] = useState<any>(0);

    // useEffect(() => {
    //     // Verifica se a aplicação está instalada no iOS
    //     const isIosInstalled = (window.navigator as any).standalone;
    //     if (isIosInstalled) {
    //         setCurrentLoginPage(1);
    //     }

    //     // Escuta o evento beforeinstallprompt para outros navegadores
    //     const handleBeforeInstallPrompt = (e: any) => {
    //         e.preventDefault();
    //     };

    //     const handleAppInstalled = () => {
    //         setCurrentLoginPage(1);
    //     };

    //     window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    //     window.addEventListener('appinstalled', handleAppInstalled);

    //     return () => {
    //         window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    //         window.removeEventListener('appinstalled', handleAppInstalled);
    //     };
    // }, []);

    // useEffect(() => {
    //     const checkPWA = () => {
    //         // Verifica se a aplicação está instalada em navegadores com suporte a PWA (não iOS)
    //         if (window.matchMedia('(display-mode: standalone)').matches) {
    //             setCurrentLoginPage(1);
    //             return;
    //         }

    //         // Fallback para outras detecções de instalação
    //         if (window.location.search.includes('source=pwa')) {
    //             setCurrentLoginPage(1);
    //             return;
    //         }
    //         setCurrentLoginPage(0);
    //     }
    //     checkPWA();
    // }, []);

    return (
        <>
            {currentLoginPage === 0 && <Instructions setPage={setCurrentLoginPage} />}
            {currentLoginPage === 1 && <Checkin setPage={setCurrentLoginPage} />}
            {currentLoginPage === 2 && <UserForm setPage={setCurrentLoginPage} />}
            {currentLoginPage === 3 && <TakeSelfie setPage={setCurrentLoginPage} />}
        </>
    );
}

export default LoginFlow;