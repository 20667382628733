import React from 'react';
import arrow_left from '../../../Assets/Icons/icon-arrow-left.svg';
import '../../../UI/Common/Buttons/ReturnButton.css';

interface Props {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type: 'button' | 'submit' | 'reset';
}

const ReturnButton: React.FC<Props> = ({ type, onClick }) => {
    return (
        <button type={type} onClick={onClick} className="return-button">
            <img src={arrow_left} alt="Return" className="return-button-icon" />
        </button>
    );
}

export default ReturnButton;