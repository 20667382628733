import '../../../UI/Pages/App/AppBackground.css';

interface Props {
    children: React.ReactNode;
    black?: boolean;
}

const AppBackground: React.FC<Props> = ({ children, black }) => {
    return (
        <div className={black ? 'app-background-black' : 'app-background'}>
            {children}
        </div>
    );
}

export default AppBackground;