import logo_extenso from '../../../Assets/logos/logo_extenso.svg';
import '../../../UI/Pages/Checkin/CheckinBackground.css';

interface Props {
    children: React.ReactNode;
}

const CheckinBackground: React.FC<Props> = ({ children }) => {
    return (
        <div className='checkin-background'>
            <div className='image-div'>
                <img className='logo' src={logo_extenso} />
                <div className='div-beta'>
                    <p className='p-beta'>BETA</p>
                </div>
            </div>
            <div style={{ width: '90%', maxWidth: '425px' }}>
                {children}
            </div>
        </div >
    );
}

export default CheckinBackground;