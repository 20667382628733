import logo_extenso from '../../../Assets/logos/logo_extenso.svg';
import '../../../UI/Pages/App/AppHeader.css';

interface Props {
    leftButton?: React.ReactNode;
    rightButton?: React.ReactNode;
    black?: boolean;
}

const AppHeader: React.FC<Props> = ({ leftButton, rightButton, black }) => {
    return (
        <div className={black ? 'app-header-black' : 'app-header'}>
            {leftButton}
            <img className='logo-header' src={logo_extenso} alt="Logo" />
            {rightButton}

        </div>
    );
}

export default AppHeader;