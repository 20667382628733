import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUserState } from "../atom";
import { useEffect, useState } from "react";
import { getMessagesToSnapshot, getUsersSnapshot } from "../../../lib/firebase/firestore";


const useCurrentUser = () => {
    const currentUser = useRecoilValue(currentUserState);
    const setCurrentUser = useSetRecoilState(currentUserState);
    const [messages, setMessages] = useState<any[]>([]);
    const startUsers: any[] = [];
    const [users, setUsers] = useState(startUsers);
    const usersList = users?.filter(user => user.id !== currentUser.id);

    //sempre que o currentUser mudar, ja salva no cache
    useEffect(() => { localStorage.setItem('currentUser', JSON.stringify(currentUser)); }, [currentUser]);

    useEffect(() => {
        if (currentUser.id) {
            getMessagesToSnapshot(currentUser.id, setMessages);
        }
        getUsersSnapshot(setUsers);
    }, []);

    const logoutCurrentUser = () => setCurrentUser({ id: '', name: '', photo: '', phone: '', gender: '' });

    const sortedMessages = messages?.sort(sortCreatedAtDesc);
    const sortedUsers = usersList?.sort(sortCreatedAtDesc);

    return {
        currentUser,
        setCurrentUser,
        logoutCurrentUser,
        messages: sortedMessages,
        usersList: sortedUsers,
    };
}

const sortCreatedAtDesc = (a: any, b: any) => {
    if (a.createdAt?.seconds === b.createdAt?.seconds) {
        return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
    }
    return b.createdAt?.seconds - a.createdAt?.seconds;
};

export default useCurrentUser;
