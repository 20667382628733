import React from "react";
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import '../../../../UI/Pages/App/LeaveRoom.css';
import logou_icon from '../../../../Assets/Icons/tabler-logout.svg';


interface Props {
    onSuccess: any;
    onCancel: any;
}

const LeaveRoom: React.FC<Props> = ({ onSuccess, onCancel }) => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1rem', width: '95%',
            marginBottom: 15,
            marginTop: 18
        }}>
            <img src={logou_icon} />
            <p className="title-interaction">Deseja sair da sala?</p >

            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 18, width: '85%' }}>
                <p className="p-leave-room-text">{`Caso deseje voltar, faça o check-in novamente`}</p >
            </div>

            <div style={{ display: "flex", gap: '1rem', alignSelf: 'stretch' }}>
                <div style={{ width: '50%' }}>
                    <DefaultButton type={"button"} text="Cancelar" onClick={onCancel} backgroundColor={"#919191"} textColor={"#1B1B1C"} />
                </div>
                <div style={{ width: '50%' }}>
                    <DefaultButton type={"button"} text="Sair" onClick={onSuccess} />
                </div>

            </div>
        </div >
    )
};

export default LeaveRoom;