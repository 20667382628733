import '../../UI/Common/DefaultSubtitle.css';

interface Props {
    text: string;
}

const DefaultSubtitle: React.FC<Props> = ({ text }) => {

    return (
        <p className='default-subtitle'>{text}</p>
    )
}

export default DefaultSubtitle;

