// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA
export function register(config: any) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `/firebase-messaging-sw.js`;
            console.log("swUrl", swUrl)

            registerValidSW(swUrl, config);
            let refreshing = false;

            // detect controller change and refresh the page
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (!refreshing) {
                    window.location.reload()
                    refreshing = true
                }
            })
        });

    }
}

function registerValidSW(swUrl: string, config: any) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            // Check for updates at start.
            registration.update();
            // Check for updates every min.
            setInterval(() => {
                registration.update();
            }, (1000 * 60));

            // detect Service Worker update available and wait for it to become installed
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                // wait until the new Service worker is actually installed (ready to take over)
                installingWorker.onstatechange = () => {
                    if (registration.waiting) {
                        if (navigator.serviceWorker.controller) {
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            console.log('Index is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                console.log("if (config && config.onSuccess) {")
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}


export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
            })
            .catch(error => {
                console.error(error.message);
            });
    }
}
