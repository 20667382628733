import '../../../UI/Pages/Checkin/Instructions.css'
import DefaultButton from '../../Common/Buttons/DefaultButton';
import CheckinBackground from "./CheckinBackground";
import arrow_right from '../../../Assets/Icons/icon-arrow-right.svg'
// import InstallButton from '../../Common/Buttons/InstallButton';

interface Props {
    setPage: any;
}

const Instructions: React.FC<Props> = ({ setPage }) => {

    const deviceType = detectDevice();
    const installMessage = deviceType === 'Android'
        ? '(Clique em Opções > Adicionar à tela de inicial)'
        : '(Clique em Compartilhar > Adicionar à Tela de Início)';

    return (
        <CheckinBackground>
            <div className="instructions-container">
                <p className='instructions-main-p'>Obrigado por entrar na brincadeira e nos ajudar a testar o Blinker, o novo app para interagir com o crush em eventos.</p>
                <p className='instructions-easy-p'>É muito fácil:</p>
                <div className='instructions-items-container'>
                    <div className="instructions-item">
                        <div className="instructions-item-background">
                            <p className="instructions-item-number">1</p>
                        </div>
                        <p className="instructions-item-p">Faça seu login</p>
                    </div>
                    <div className="instructions-item">
                        <div className="instructions-item-background">
                            <p className="instructions-item-number">2</p>
                        </div>
                        <p className="instructions-item-p">Selecione a pessoa com quem deseja interagir</p>
                    </div>
                    <div className="instructions-item">
                        <div className="instructions-item-background">
                            <p className="instructions-item-number">3</p>
                        </div>
                        <p className="instructions-item-p">Escolha a interação e envie</p>
                    </div>
                    <p className='instructions-subtitle-p'>Ao receber uma interação, você pode retribuir, ou partir para uma conversa pessoalmente.</p>
                </div>

                <div className='instructions-tips-container'>
                    <p className='instructions-tip-p'>Para uma melhor experiência:</p>
                    <p className='instructions-tip-p'><strong>Permita notificações:</strong> seja avisado quando alguém interagir com você.</p>
                    <p className='instructions-tip-p'><strong>Adicione o ícone na tela inicial:</strong> para usar o Blinker como app {installMessage}.</p>

                    {/* <InstallButton /> */}
                </div>
                <div style={{ width: '100%', marginBottom: '1.5rem', marginTop: '0.5rem' }}>
                    <DefaultButton type={'button'} text='ENTRAR' iconRight={arrow_right} onClick={() => setPage(1)} />
                </div>
            </div>
        </CheckinBackground>
    )
}


export default Instructions;


function detectDevice() {
    const userAgent = navigator.userAgent || (navigator as any).NavigatorID.vendor || (window as any).opera;

    // Verifica se o dispositivo é iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return 'iOS';
    }

    // Verifica se o dispositivo é Android
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // Caso o dispositivo não seja identificado como iOS ou Android
    return 'Android';
}