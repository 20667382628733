import DefaultTitle from "../../Common/DefaultTitle";
import CheckinBackground from "./CheckinBackground";
import DefaultSubtitle from "../../Common/DefaultSubtitle";
import '../../../UI/Pages/Checkin/TakeSelfie.css';
import camera_icon from '../../../Assets/Icons/icon-camera.svg';
import right_icon from '../../../Assets/Icons/icon-arrow-right.svg';
import spacer_icon from '../../../Assets/Icons/icon-spacer-24-px.svg';
import ReturnButton from "../../Common/Buttons/ReturnButton";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import close_icon from '../../../Assets/Icons/selfie-icon-close.svg';
import reload_icon from '../../../Assets/Icons/reload_icon.svg';

import { useRef, useState } from "react";
import useCurrentUser from "../../State/Hooks/useCurrentUser";
import { updateUserImage } from "../../../lib/firebase/storage";
import { createUser } from "../../../lib/firebase/firestore";
import Loading from "../../Common/Loading";
import ErrorMessage from "../../Common/ErrorMessage";
import { useNavigate } from "react-router-dom";
import PageTransition from "../../Common/PageTransition";
import DefaultModal from "../../Common/DefaultModal";
import AppCamera from "../../Common/AppCamera";
import useRooms from "../../State/Hooks/useRooms";

interface Selfie {
    file: Blob;
    url: string;
}

interface Props {
    setPage: any;
}

//Page 3
const TakeSelfie: React.FC<Props> = ({ setPage }) => {
    const { currentUser, setCurrentUser } = useCurrentUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [selfie, setSelfie] = useState<Selfie | null>(null);
    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const [showCameraModal, setShowCameraModal] = useState<boolean>(false);
    const inputRef: any = useRef(null);
    const navigate = useNavigate();

    window.scrollTo(0, 0);
    const enterRoom = async () => {
        if (selfie) {
            setIsLoading(true);
            try {
                // Array.from({ length: 20 }).map(async () => {
                //     const newUser = await createUser(currentUser);
                //     const photo = await updateUserImage(newUser.id, selfie.file);
                // })
                const newUser = await createUser(currentUser);
                const photo = await updateUserImage(newUser.id, selfie.file);
                setCurrentUser(prev => ({ ...prev, ...newUser, photo }));
                navigate('/');
            } catch (error) {
                setShowError(true);
            }
            setIsLoading(false);
        }
    }

    const handleCapture = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (e.target.files.length !== 0) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setSelfie({ file, url });
            }
        }
    }

    return (
        <>
            {!isCameraOpen
                ? (<CheckinBackground>
                    <PageTransition>
                        <div className="take-selfie-container">
                            {selfie ? (
                                <div className="image-container">
                                    <img src={selfie.url} alt="Selfie" className="selfie-image" />
                                    {/* <button className="close-button" onClick={() => setSelfie(null)}>
                                        <img src={close_icon} alt="Excluir" />
                                        </button> */}
                                </div>
                            ) : (
                                <>
                                    <DefaultTitle text={"Tire uma selfie"} />
                                    {/* <button className="camera-button" onClick={() => setIsCameraOpen(true)}> */}
                                    <button className="camera-button" onClick={() => setShowCameraModal(true)}>
                                        <img src={camera_icon} alt="Ícone da câmera" className="camera-icon" />
                                        <p className="camera-text">Abrir câmera</p>
                                    </button>
                                    <DefaultSubtitle text={"*É importante que as outras pessoas consigam identificar você"} />
                                    <input
                                        id="selfieInput"
                                        type="file"
                                        accept="image/*"
                                        // capture
                                        aria-label="Take Photo"
                                        onChange={handleCapture}
                                        ref={inputRef}
                                        style={{ display: 'none' }} // Esconde o input
                                    />
                                </>
                            )}
                            <div className="actions">
                                <ReturnButton type="button" onClick={() => setPage(2)} />
                                <DefaultButton
                                    type="button"
                                    text={"Entrar na sala"}
                                    iconRight={right_icon}
                                    iconLeft={spacer_icon}
                                    disabled={!selfie}
                                    onClick={enterRoom}
                                />
                            </div>
                        </div>
                    </PageTransition>
                    {selfie && (
                        <div className="take-selfie-remake-container" onClick={() => { setSelfie(null); setIsCameraOpen(true) }} >
                            <img src={reload_icon} />
                            <p className="take-selfie-remake-photo">Refazer foto</p>
                        </div>
                    )}
                    <DefaultModal show={showCameraModal} onClose={() => setShowCameraModal(false)} showCloseButton={true}>
                        <div className="camera-modal-main-div">
                            <p className="camera-modal-title">Abrir câmera</p>
                            {/* <DefaultButton onClick={() => { inputRef.current.click(); setShowCameraModal(false) }} text="Câmera do Celular" type={"button"} /> */}
                            <DefaultButton onClick={() => { inputRef.current.click(); setShowCameraModal(false) }} text="Galeria" type={"button"} />
                            <DefaultButton onClick={() => { setIsCameraOpen(true); setShowCameraModal(false) }} text="Câmera do App" type={"button"} backgroundColor="#1B1B1C" textColor="#FF6D8B" border="2px solid #FF6D8B" />
                        </div>
                    </DefaultModal>
                    <Loading show={isLoading} />
                    <ErrorMessage show={showError} onClose={() => setShowError(false)} />
                </CheckinBackground >)
                : <AppCamera setImage={setSelfie} setIsOpen={setIsCameraOpen} />

            }
        </>
    )
}
export default TakeSelfie;