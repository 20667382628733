import { useEffect, useState } from 'react';
import useCurrentUser from '../State/Hooks/useCurrentUser';
import MainRoutes from '../Pages/App/MainRoutes';
import LoginFlow from './Checkin/LoginFlow';

const ResolveAuth = () => {
  const [content, setContent] = useState<any>(null);

  const { currentUser } = useCurrentUser();

  useEffect(() => {

    if (currentUser.id) {
      setContent(<MainRoutes />);
    } else {
      setContent(<LoginFlow />);
    }

  }, [currentUser]);

  return (<>
    {content}
  </>);
};

export default ResolveAuth;