import { firestore } from './client'

import {
    collection,
    query,
    getDocs,
    getDoc,
    addDoc,
    QueryDocumentSnapshot,
    DocumentData,
    onSnapshot,
    deleteDoc,
    setDoc,
    doc,
    where,
    DocumentSnapshot,
    serverTimestamp,
    Timestamp
} from "firebase/firestore";

function parseDoc(doc: QueryDocumentSnapshot<DocumentData, DocumentData> | DocumentSnapshot): any {
    return {
        id: doc.id,
        ...doc.data()
    }
}

export async function createUser(data: any) {
    const user = await addDoc(collection(firestore, 'users'), { ...data, createdAt: serverTimestamp(), loggedIn: true });
    return parseDoc(await getDoc(user));
}

export async function sendMessage(userFrom: string, userTo: string, message: any) {
    const data = {
        userFrom,
        userTo,
        message,
        createdAt: serverTimestamp(),
        closed: true
    }
    const newMessage = await addDoc(collection(firestore, 'messages'), data);
    return newMessage.id;
}

export function getMessagesToSnapshot(userTo: string, cb: any) {
    const q = query(collection(firestore, "messages"), where("userTo", "==", userTo));

    const unsubscribe = onSnapshot(q, snapshot => {
        const results = snapshot.docs.map(parseDoc);
        cb(results);

    });
    return unsubscribe;
}

export async function updateUser(userId: string, data: any, merge: boolean = true) {
    await setDoc(doc(firestore, `users/${userId}`), data, { merge });
}

export async function getUser(userId: string) {
    const userDoc = await getDoc(doc(firestore, 'users', userId));
    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        return null;
    }
}

export function getUsersSnapshot(cb: any) {
    const q = query(collection(firestore, "users"), where("photo", "!=", false));

    const unsubscribe = onSnapshot(q, snapshot => {
        const results = snapshot.docs.map(parseDoc);
        cb(results);

    });
    return unsubscribe;

}

export async function deleteUser(userId: string) {
    await deleteDoc(doc(firestore, `users/${userId}`));
}


export function formatTimestamp(timestamp: Timestamp): string {
    const date = timestamp.toDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export async function updateMessageClosedStatus(messageId: string, closed: boolean, merge: boolean = true) {
    await setDoc(doc(firestore, `messages/${messageId}`), { closed }, { merge });
}

export async function getRooms() {
    const q = query(collection(firestore, "rooms"), where("open", "==", true));

    const documents = await getDocs(q);
    return documents.docs.map(parseDoc);
}