import '../../../../UI/Pages/App/Room/FilterRoom.css';
import { useState } from 'react';

interface Props {
    filters: { value: string; label: string; }[];
    selected: string;
    setFilter: any;
}

const FilterRoom: React.FC<Props> = ({ filters, selected, setFilter }) => {

    return (
        <div className='filter-room-main-div'>
            <div className='filter-room-div'>
                {filters.map((filter) => (
                    <button
                        className={`filter-room-button ${selected === filter.value ? 'selected' : ''}`}
                        key={filter.value}
                        onClick={() => setFilter(filter.value)}
                    >
                        {filter.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FilterRoom;
