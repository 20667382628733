import React, { useState } from 'react';
import '../../../../UI/Pages/App/Room/Menu.css';
import close_icon from '../../../../Assets/Icons/icon-close-white.svg'
import camera_icon from '../../../../Assets/Icons/icon-camera-oulined.svg'
import location_icon from '../../../../Assets/Icons/icon-location.svg';
import logout_icon from '../../../../Assets/Icons/tabler-logout.svg';
import useCurrentUser from '../../../State/Hooks/useCurrentUser';
import SquareIcon from '../../../Common/SquareIcon';
import useRooms from '../../../State/Hooks/useRooms';
import DefaultModal from '../../../Common/DefaultModal';
import LeaveRoom from './LeaveRoom';
import Loading from '../../../Common/Loading';
import ErrorMessage from '../../../Common/ErrorMessage';
import { updateUser } from '../../../../lib/firebase/firestore';
import { useNavigate } from 'react-router-dom';
import PageTransition from '../../../Common/PageTransition';
import AppCamera from '../../../Common/AppCamera';

type Props = {
    // id: string;
    // name: string;
    // photo: string;
}
interface Selfie {
    file: Blob;
    url: string;
}
const Menu: React.FC<Props> = ({ }) => {
    const [showError, setShowError] = useState<boolean>(false);
    const { currentUser, logoutCurrentUser } = useCurrentUser();
    const { currentRoom, roomsList } = useRooms();
    const [showLeaveRoom, setShowLeaveRoom] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selfie, setSelfie] = useState<Selfie | null>(null);
    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const leaveRoom = async () => {
        setIsLoading(true);
        try {
            if (currentUser.id) {
                await updateUser(currentUser.id, { loggedIn: false });
                // await deleteUser(currentUser.id)
                // if (currentUser.photo) {
                //     await deleteImage(currentUser.id, currentUser.photo)
                // }
                logoutCurrentUser();
            }
        } catch (error) {
            setShowError(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            {!isCameraOpen
                ? (
                    <PageTransition>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }} onClick={() => navigate('/')}>
                            <img src={close_icon} style={{ paddingRight: '0.1rem', margin: '1rem' }} />
                        </div>
                        <div className='menu-perfil-div'>
                            <div className="menu-perfil-photo-container">
                                <img className='menu-perfil-photo' src={currentUser.photo} alt={currentUser?.name} />
                                {/* <div className="menu-camera-icon-container" onClick={() => setIsCameraOpen(true)}>
                                    <img src={camera_icon} alt="camera icon" className="menu-camera-icon" />
                                </div> */}
                            </div>
                            <p className='menu-perfil-name'>{currentUser.name}</p>
                        </div>
                        <div className='menu-location-div'>
                            <div style={{ paddingBlock: '1rem', paddingLeft: '1rem' }}>
                                <SquareIcon src={location_icon} />
                            </div>
                            <div className='menu-center-div' translate="no">
                                <h2 className='menu-h2-title'>{currentRoom.title}</h2>
                                <p className='menu-p-text'>{currentRoom.address}</p>
                            </div>
                        </div>
                        <div className='menu-time-div'>
                            <p className='menu-time-p'> Horário: 23:00 - 6:00</p>
                        </div>
                        <div className='menu-leave-app' onClick={() => setShowLeaveRoom(true)}>
                            <div style={{ display: 'flex', alignContent: 'center', gap: '0.5rem', flex: 1, paddingLeft: '1rem', paddingBlock: '0.2rem' }}>
                                <img src={logout_icon} alt='Logoff' />
                                <p className='menu-leave-p'>Sair do app</p>
                            </div>
                        </div>
                    </PageTransition>
                )
                : <AppCamera setImage={setSelfie} setIsOpen={setIsCameraOpen} />
            }




            <DefaultModal show={showLeaveRoom} onClose={() => setShowLeaveRoom(false)}>
                <LeaveRoom
                    onCancel={() => setShowLeaveRoom(false)}
                    onSuccess={() => { setShowLeaveRoom(false); leaveRoom() }} />
            </DefaultModal>
            <Loading show={isLoading} />
            <ErrorMessage show={showError} onClose={() => setShowError(false)} />
        </>
    );
};

export default Menu;