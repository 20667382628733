import { useRecoilValue, useSetRecoilState } from "recoil";
import { roomState } from "../atom";
import { useEffect, useState } from "react";
import { getRooms } from '../../../lib/firebase/firestore';


const useRooms = () => {
    const currentRoom = useRecoilValue(roomState);
    const setCurrentRoom = useSetRecoilState(roomState);
    const [roomsList, setRoomsList] = useState<any>();

    //sempre que o currentUser mudar, ja salva no cache
    useEffect(() => { localStorage.setItem('currentRoom', JSON.stringify(currentRoom)) }, [currentRoom]);

    useEffect(() => {
        const loadRooms = async () => setRoomsList(await getRooms());
        loadRooms();
    }, [])


    return {
        currentRoom,
        setCurrentRoom,
        roomsList
    };
}

export default useRooms;
