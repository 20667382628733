import { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "./config";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from 'firebase/messaging';

export const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const messaging = getMessaging();
export const { vapidKey } = firebaseConfig;
