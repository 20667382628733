import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import AppBackground from '../Pages/App/AppBackground';
import AppHeader from '../Pages/App/AppHeader';
import icon_spacer from '../../Assets/Icons/icon-spacer-24-px.svg';
import arrow_left from '../../Assets/Icons/icon-arrow-left-white.svg';
import take_photo_icon from '../../Assets/Icons/take-photo-icon.svg';
import reverse_camera_icon from '../../Assets/Icons/icon-park-solid-reverse-lens.svg';
import PageTransition from './PageTransition';
import '../../UI/Common/AppCamera.css'

interface OpenCameraProps {
    setImage: any;
    setIsOpen: any;
}

const AppCamera: React.FC<OpenCameraProps> = ({ setImage, setIsOpen }) => {
    const webcamRef = useRef<any>(null);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc) {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    if (facingMode === 'user') {
                        // Espelha a imagem horizontalmente
                        ctx.translate(canvas.width, 0);
                        ctx.scale(-1, 1);
                    }
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' });
                            const url = URL.createObjectURL(file);
                            setImage({ file, url });
                            setIsOpen(false);
                        }
                    }, 'image/jpeg');
                }
            };
        }
    }, [webcamRef, facingMode, setImage, setIsOpen]);

    const handleSwitchCamera = () => {
        setFacingMode(prevFacingMode => (prevFacingMode === 'user' ? 'environment' : 'user'));
    };

    const videoConstraints = {
        facingMode: facingMode
    };

    return (
        <AppBackground black={true}>
            <PageTransition>
                <AppHeader
                    black={true}
                    leftButton={
                        <button className='app-header-icon-button' onClick={() => setIsOpen(false)}>
                            <img src={arrow_left} alt="Logout Icon" />
                        </button>}

                    rightButton={<img src={icon_spacer} />}
                />
                <div className='app-camera-main-div'>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={625}
                        height={500}
                        videoConstraints={videoConstraints}
                        style={{
                            transform: facingMode === 'user' ? 'scaleX(-1)' : 'none'
                        }}
                    />
                    <div className='app-camera-buttons-div'>
                        <div className='switch-app-camera-hidden'> </div>
                        <div onClick={capture}>
                            <img src={take_photo_icon} alt='Tirar Foto' />
                        </div>
                        <button className='switch-app-camera-button' onClick={handleSwitchCamera}>
                            <img className='reverse-app-camera-icon' src={reverse_camera_icon} alt='Inverter câmera' />
                        </button>
                    </div>
                </div>
            </PageTransition >
        </AppBackground>
    );
};

export default AppCamera;
