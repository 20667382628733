
import React from 'react';
import '../../../UI/Common/Inputs/FormInput.css';

interface Props {
    type: string;
    id: string;
    value?: any;
    onChange?: any;
    placeholder?: string;
    defaultValue?: string;
    maxLength?: number;
}

const FormInput: React.FC<Props> = ({ type, id, value, placeholder, defaultValue, onChange, maxLength }) => {

    return (
        <input
            type={type}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            className="form-input"
        />
    );
}

export default FormInput;